import { useEffect, useState } from 'react'

import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'

import * as Routes from '../../routes'
import { capitalize } from '../editor/common/commonUtils'
import { standardHeaders } from '../entries/utils'
import { CopyableText } from '../shared/CopyableText'
import DropdownSelector from './DropdownSelector'

const handleBack = () => {
  history.back()
}

const IdentifiersDetail = ({ identifiers, details, dealership }) => {
  const identifiersData = identifiers?.sort((a, b) => a.name.localeCompare(b.name))
  const [data, setData] = useState(details)
  const [loading, setLoading] = useState(false)
  const [selectedIdentifier, setSelectedIdentifier] = useState(identifiersData[0])
  const [selectedDealership, setSelectedDealership] = useState({})
  const [dealerships, setDealerships] = useState([])
  const dealershipFilterables = ['Websites', 'Locations']

  const getKeys =
    data && data.length > 0 ? Object.keys(data[0]).filter((key) => key !== 'show_path') : []

  const getUrl = () => {
    const by_dealership = dealershipFilterables.includes(selectedIdentifier?.label)
      ? selectedDealership?.slug || dealerships[0]?.slug || dealership
      : null
    const default_url = `${Routes.identifiers_path()}.json?name=${selectedIdentifier?.name}`
    return by_dealership ? `${default_url}&by_dealership=${by_dealership}` : default_url
  }

  const handleDetails = async () => {
    const response = await fetch(getUrl(), {
      method: 'GET',
      headers: standardHeaders,
    })
    const data = await response.json()
    setData(data?.details)
    if (selectedIdentifier?.label === 'Dealerships') {
      if (dealerships.length === 0) {
        setDealerships(data?.details)
      }
    }
    return data
  }

  useEffect(() => {
    if (dealership?.id && selectedIdentifier?.label === 'Dealerships') {
      setDealerships([dealership])
      setSelectedDealership(dealership)
      setData([dealership])
    } else {
      setLoading(true)
      handleDetails().then((data) => {
        if (
          data?.details?.length > 0 &&
          dealershipFilterables.includes(selectedIdentifier?.label) &&
          selectedDealership?.slug !== data?.details[0].dealership_slug
        ) {
          setSelectedDealership(
            dealerships.find((dealership) => dealership.slug === data?.details[0].dealership_slug)
          )
        }
        setLoading(false)
      })
    }
  }, [selectedIdentifier, selectedDealership])

  const mapDetail = (key, detail) => {
    if (key === 'url') {
      return <a href={`${detail.url}`}>{detail[key]}</a>
    } else if (key === 'name' && detail.show_path) {
      return <a href={`${detail.show_path}`}>{detail[key]}</a>
    } else return <CopyableText text={detail[key]} />
  }

  const props = {
    selectedIdentifier,
    selectedDealership,
    setSelectedIdentifier,
    setSelectedDealership,
    identifiersData,
    dealerships,
  }

  const showDealershipFilter =
    selectedIdentifier &&
    dealershipFilterables.includes(selectedIdentifier.label) &&
    selectedDealership &&
    dealerships?.length > 0

  let [page, setPage] = useState(1)
  const [rows, setRows] = useState(20)

  const onPageChange = (event) => {
    setPage(event.page + 1) // PrimeReact pages are 0-based; Kaminari pages are 1-based
  }

  return (
    <div className="box p-3 mb-3">
      <h1>Listing Identifiers</h1>
      {loading ? (
        <div className="d-flex justify-content-center">
          <div className="loading-spinner"></div>
        </div>
      ) : (
        <div>
          <div className="row">
            <DropdownSelector props={props} title="Identifier Module::" />
            {showDealershipFilter && (
              <DropdownSelector props={props} title="Filter by Dealership::" />
            )}
          </div>
          <br />
          <div className="box">
            {data && data?.length > 0 && (
              <DataTable
                value={data}
                className="p-datatable-gridlines"
                paginator
                rows={rows}
                first={(page - 1) * rows}
                onPage={onPageChange}
                totalRecords={data.length}
              >
                {getKeys.map((key, index) => (
                  <Column
                    key={`column_${index}`}
                    field={key}
                    header={capitalize(key)}
                    body={(rowData) => mapDetail(key, rowData)}
                  />
                ))}
              </DataTable>
            )}
          </div>
          <br />
          <a href="#" onClick={handleBack} className="mr-auto mb-2">
            Back
          </a>
        </div>
      )}
    </div>
  )
}

export default IdentifiersDetail
