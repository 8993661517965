import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'

import * as Routes from '../routes'
import Loading from './Loading'
import IdentifiersDetail from './integration/IdentifiersDetail'

const fetchSlugData = async ({ queryKey }) => {
  const [, dealershipSlug] = queryKey
  const fetchUrl = dealershipSlug
    ? `${Routes.slug_details_dealership_path(dealershipSlug)}.json`
    : `${Routes.identifiers_path()}.json`

  const response = await fetch(fetchUrl)
  if (!response.ok) throw new Error('Failed to fetch Slug details')
  return response.json()
}
const IntegrationIdentifiers = () => {
  const { dealershipSlug } = useParams()
  const { data, isError, isLoading } = useQuery({
    queryKey: ['dealerships', dealershipSlug],
    queryFn: fetchSlugData,
  })

  if (isLoading)
    return (
      <div className="d-flex justify-content-center">
        <Loading />
      </div>
    )
  if (isError) return <Error />
  const { identifiers = [], details = [], dealership = {} } = data || {}

  return (
    <div className="container-fluid">
      <IdentifiersDetail identifiers={identifiers} details={details} dealership={dealership} />
    </div>
  )
}

export default IntegrationIdentifiers
