import { Dropdown } from 'primereact/dropdown'

const DropdownSelect = ({ props, title }) => {
  const identifierDropdown = title === 'Identifier Module::'
  const {
    selectedIdentifier,
    selectedDealership,
    setSelectedIdentifier,
    setSelectedDealership,
    identifiersData,
    dealerships,
  } = props

  const mapData = {
    value: identifierDropdown ? selectedIdentifier : selectedDealership,
    options: identifierDropdown ? identifiersData : dealerships,
    optionLabel: identifierDropdown ? 'label' : 'name',
    style: { minWidth: identifierDropdown ? '15rem' : '20rem' },
  }

  return (
    <div
      className={`${!identifierDropdown ? 'float-right text-right' : ''} col-md-6`}
      id={`${identifierDropdown ? 'identifier_dropdown' : 'dealership_dropdown'}`}
    >
      <label className="form-control-label string optional">
        <strong>{title}</strong>
      </label>{' '}
      &nbsp;
      <Dropdown
        value={mapData.value}
        options={mapData.options}
        onChange={(e) =>
          identifierDropdown
            ? setSelectedIdentifier(e.target.value)
            : setSelectedDealership(e.target.value)
        }
        optionLabel={mapData.optionLabel}
        style={mapData.style}
      />
    </div>
  )
}

const DropdownSelector = connectSelector(DropdownSelect)

export default DropdownSelector

function connectSelector(Component) {
  const DropdownSelect = (props) => {
    return <Component {...props} />
  }

  return DropdownSelect
}
